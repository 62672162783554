import styled from "styled-components";
import rankingimg from "../ranking.jpg";

function RankingCurtain() {
  return (
    <>
      <img src={rankingimg}></img>
    </>
  );
}

export default RankingCurtain;
